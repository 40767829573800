@import "./font.sass"
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')

body *
  font-family: 'Roboto', sans-serif !important
  ::-webkit-scrollbar
    width: 4px
  ::-webkit-scrollbar-track
    background: #DDDDDD
  ::-webkit-scrollbar-thumb
    background-color: #999999
    border-radius: 13px

@media screen and (min-width: 1024px)
  .modal.show .modal-dialog
    max-width: 50% !important
  .modal.show .modal-dialog.small-dialog
    max-width: 40% !important

.spinner-border
  display: flex
  justify-content: center
  align-items: center
  border: none

.fade-in-enter
  opacity: 0

.fade-in-enter-active
  opacity: 1
  transition: all 0.5s

.fade-in-exit
  opacity: 1

.fade-in-exit-active
  opacity: 0
  transition: all 0.5s

div.react-datepicker
  border-radius: 0
  border: 1px solid #9AA3E1
  &__triangle
    &::before,&::after
      left: -123px!important
  &__month
    margin: 0
  &__day
    margin: 0
    border-radius: 0
    width: 35px
    line-height: 35px
    &:hover
      border-radius: 0
    &--disabled
      cursor: not-allowed
    &-name
      margin: 0
      width: 35px
      line-height: 35px

.form-control:focus
  border-color: #86b7fe !important
  box-shadow: 0 0 0 .25rem rgba(13,110,253,.25) !important

.border-gradient-animation
  position: relative
  overflow: hidden
  z-index: 1
  &::before
    content: ""
    display: block
    border-radius: 5px
    position: absolute
    inset: 0.25em
    z-index: -1
    background: inherit
  &::after
    content: ""
    display: block
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: -2
    transform: rotate(0deg)
    animation: gradient 2s ease alternate infinite
    background: linear-gradient(45deg, #6868cf 0%,yellow 70%,red 100%)
    background-size: 300% 300%

@keyframes gradient
  0%
    background-position: 0% 50%
  50%
    background-position: 95% 50%
  100%
    background-position: 0% 50%

.flatpickr-month 
  background: #fff !important
  .cur-month 
    color: #191919 !important
    font-weight: 500 !important
  
  .numInputWrapper 
    color: #191919
    & input 
      font-weight: 500 !important

.flatpickr-weekdays 
  background: #fff !important
  .flatpickr-weekday 
    background: #fff !important
    font-size: 10px !important
    color: #7F7F7F
  

.flatpickr-day
  &.selected
    background: #0844B4 !important
    border-radius: 8px !important
  &.inRange
    background: #83A1D9 !important
    border-radius: 8px !important
    color: #fff !important
    box-shadow: none !important
    -webkit-box-shadow: none !important


.border-radius-32
  border-radius: 32px

.bg-blue-main
  background-color: #134B98 !important

.color-blue-main
  color: #134B98 !important

.widgetHeight
  height: 435px

.list-notifications-users
  margin-left: 200px
  margin-bottom: 6rem

.border-radius-left
  border-radius: 0.5rem 0 0 0.5rem

.border-radius-top-right
  border-radius: 0 0.5rem 0 0

.border-radius-bottom-right
  border-radius: 0  0 0.5rem 0

.separator
  width: 1px
  background-color: #FFFFFF

.CalendarDay
  border: none !important

.maxScreen-1920
  max-width: 1920px

.CalendarDay__selected
  background-color: #0844B4 !important
  border-radius: 8px !important
.CalendarDay__hovered_span,
.CalendarDay__selected_span
  color: #fff !important
  background-color: #83A1D9 !important
  border-radius: 8px !important
.DateInput
  border-radius: 8px !important
  input
    padding-top: 0 !important
    padding-bottom: 0 !important
.DateRangePickerInput
  border-radius: 8px !important
  input
    border-radius: 8px !important
    border-bottom: none !important
    font-size: 14px !important
    font-weight: 400 !important
    padding-top: 0.5rem !important
    padding-bottom: 0.4rem !important
    color: #191919 !important
    &::first-letter
      text-transform: capitalize !important
  .DateInput_input__focused
    border: none !important
 
.DateRangePickerInput_arrow
  &::before
    content: '-'
  svg
    display: none
.ql-snow 
  .ql-tooltip[data-mode=link]::before 
    content: "Ссылка:" !important 
  .ql-tooltip 
    &.ql-editing 
      a 
        &.ql-action 
          &::after 
            content: 'Сохранить' !important
      input[type=text]
        &::placeholder
          color: #ffffff


@media screen and (max-width: 600px)
  .react-joyride__tooltip
    max-width: 300px !important

  .list-notifications-users
    margin-left: 0
   
